import React, { useState, useContext, useEffect } from 'react'
import Layout from '../layout'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Box, CssBaseline } from '@material-ui/core'
import ACC_THEME from '../../themes'
import PageContext from '@context'
import get from 'lodash/get'
import PageContainer from '@system/page-container'

const useStyles = makeStyles({
  body: {
    marginTop: '-100px',
    position: 'relative',
  },
})

const ResourcesIndex = (props) => {
  const [isVisible, setVisibility] = useState(null)

  const updateHiddenFieldsValue = (value) => {
    setVisibility(value)
  }

  const updateUrlParam = (URLParams) => {
    const paramsString = new URL(document.location.href)
    URLParams &&
      URLParams.length > 0 &&
      URLParams.forEach((param) => {
        paramsString.searchParams.append(
          param.filterDataPath,
          param.options.reduce((acc, value) => acc).fieldOptions
        )
      })

    window.history.pushState({}, '', paramsString.href)
    window.location.href = paramsString.href
    setVisibility(true)
  }
  const { headerImage, body, filters, cardModules, metaData } = props.blok
  const classes = useStyles()
  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <Box>
          {!!headerImage && <Box>{renderBloks(headerImage)}</Box>}
          <PageContainer>
            <Box className={classes.body}>
              {!!body && renderBloks(body)}
              {!!filters &&
                renderBloks(filters, {
                  updateHiddenFieldsValue,
                  isResourcesIndexView: isVisible,
                })}
              {!isVisible &&
                !!cardModules &&
                renderBloks(cardModules, {
                  updateUrlParam: updateUrlParam,
                  updateHiddenFieldsValue,
                })}
            </Box>
          </PageContainer>
        </Box>
      </ThemeProvider>
    </Layout>
  )
}

export default ResourcesIndex
